/**
* Generated automatically at built-time (2025-01-17T10:14:37.832Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "free-standing-outdoor-kitchen-modules",templateKey: "sites/101-1525d9c3-d336-4148-8e87-fd71949078bd"};