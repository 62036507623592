import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import { SearchableDropdownBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import DropdownMenu from "./DropdownMenu";
import DropdownToggle from "./DropdownToggle";
import { validatePattern } from "./utils";

// alternative component (bigger mem footprint!): https://www.npmjs.com/package/react-select-plus
// note: our component takes advantage of existing `react-bootstrap` so its footprint should be smaller (~7.5KB)

/**
 * @description A searchable dropdown component
 * @param {Object} props The component properties
 * @returns {JSX}
 */
const SearchableDropdown = React.forwardRef((props, ref) => {
  // Use a ref to store the previous props
  const prevProps = useRef();

  const defaultValue =
    "undefined" === typeof props.value
      ? (
          props.items.find(
            item => !item.divider && !item.header && item.selected
          ) || {}
        ).key
      : props.value;

  const [value, setValue] = useState(defaultValue);
  const [isOpen, setIsOpen] = useState(false);
  const [toggled, setToggled] = useState(false);

  // track the post-rendering props.value changes
  // this is necessary to sync state with external props change
  useEffect(() => {
    // Check if props.value have changed
    if (prevProps.current) {
      if (props.value !== prevProps.current.value) {
        // update the value status with the new props
        if (props.value !== value) {
          setValue(props.value);
        }
      }
    }
    // Update the prevProps ref with the current props for the next render
    prevProps.current = props;
  }, [value, props]);

  // handle ctrl+v (paste keyboard event)
  useEffect(() => {
    if (!ref) {
      return;
    }

    const el = ref.current;
    const onPaste = e => {
      e.preventDefault();

      const pastedText = (e.clipboardData || window.clipboardData).getData(
        "text"
      );

      if (validatePattern(e, pastedText)) {
        el.value = pastedText;
        el.dispatchEvent(new Event("change"));
        return true;
      }

      return false;
    };

    el.addEventListener("paste", onPaste);

    // the clean-up function
    return function () {
      el.removeEventListener("paste", onPaste);
    };
  }, [ref, props.pattern]);

  const onValueChanged = (value, e) => {
    setValue(value);

    if ("function" === typeof props.onChange) {
      props.onChange(e);
    }
  };

  const onToggle = e => setToggled(!toggled);

  const onChange = e => onValueChanged(e.currentTarget.value, e);

  const onKeyDown = e => {
    if ("function" === typeof props.onKeyDown) {
      props.onKeyDown(e);
    }

    // browser Autofill simulating keydown?
    if ("Unidentified" === e.key) {
      if (ref && "function" === typeof props.onAutofill) {
        e.persist(); // must be preserved for later reuse purpose

        // !!! it's essential to pass the element (not its value) since its value is about to be replace by the autofill function afterwards
        props.onAutofill(ref.current, newValue => {
          if (newValue !== ref.current.value) {
            onValueChanged(newValue, e);
            validatePattern(e, newValue);
          }
        });
      }
    } else {
      // a real keydown event
      validatePattern(e, e.currentTarget.value);
    }
  };

  // the dropdown input editor with toggle support
  const toggle = (
    <Dropdown.Toggle
      ref={ref}
      as={DropdownToggle}
      value={value}
      placeholder={props.placeholder}
      readOnly={props.readOnly}
      disabled={props.disabled}
      isInvalid={props.isInvalid}
      isValid={props.isValid}
      feedback={props.feedback}
      autoComplete={props.autoComplete}
      size={props.size}
      toggled={toggled}
      onToggle={onToggle}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onKeyUp={props.onKeyUp}
      required={props.required}
      pattern={props.pattern}
    />
  );

  // the collapsed dropdown list menu/items
  const menu = (
    <DropdownMenu
      selectedValue={value}
      items={props.items}
      style={props.style}
      placeholder={(props.i18n || {}).SEARCH_PLACEHOLDER || "Type to filter..."}
      ariaLabelledBy={props.ariaLabeledBy}
      showGroupCount={props.showGroupCount}
      size={props.size}
      isOpen={isOpen}
      onSelect={onValueChanged}
      searchable={props.searchable}
    />
  );

  return (
    <Dropdown
      className={getComponentClassName(
        SearchableDropdownBS,
        null,
        props.className
      )}
      onToggle={(isOpen, event, metadata) => setIsOpen(isOpen)}
    >
      {toggle}
      {menu}
    </Dropdown>
  );
});

SearchableDropdown.propTypes = {
  ...ItemsAwareProps(),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  ariaLabeledBy: PropTypes.string,
  style: PropTypes.object,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isValid: PropTypes.bool,
  onChange: PropTypes.func,
  onAutofill: PropTypes.func,
  showGroupCount: PropTypes.bool,
  size: PropTypes.oneOf(["sm", "lg"]),
  feedback: PropTypes.string,
  i18n: PropTypes.object,
  searchable: PropTypes.bool,
  className: PropTypes.string
};

SearchableDropdown.defaultProps = {
  ariaLabeledBy: "Searchable dropdown menu",
  showGroupCount: true,
  searchable: true,
  items: []
};

export default SearchableDropdown;
