import {
  CHECKOUT_FETCH_SHIPMENT_METHODS_FAILURE,
  CHECKOUT_FETCH_SHIPMENT_METHODS_REQUEST,
  CHECKOUT_FETCH_SHIPMENT_METHODS_SUCCESS,
  CHECKOUT_SET_SHIPMENT_METHOD
} from "../actionTypes";

const checkoutShipment = (state = {}, action) => {
  const { shipmentMethod } = action;

  switch (action.type) {
    case CHECKOUT_SET_SHIPMENT_METHOD:
      return { ...state, shipmentMethod };
    default:
      return state;
  }
};

const checkoutShipmentMethodsResult = (state, action) => {
  const newState = {
    methods: null,
    cart: null,
    error: null,
    isFetching: false
  };

  let mostExpensiveItem;

  switch (action.type) {
    case CHECKOUT_FETCH_SHIPMENT_METHODS_REQUEST:
      return { ...newState, isFetching: true };
    case CHECKOUT_FETCH_SHIPMENT_METHODS_SUCCESS:
      // see issue #186
      mostExpensiveItem = action.cart
        ? action.cart.reduce(
            (carry, item) =>
              !carry || carry.newPrice < item.product.newPrice
                ? item.product
                : carry,
            null
          )
        : null;

      return {
        ...newState,
        methods: action.methods.filter(
          method =>
            !method.brandId ||
            !mostExpensiveItem ||
            +mostExpensiveItem.brand.id === +method.brandId
        ),
        isFetching: false
      };
    case CHECKOUT_FETCH_SHIPMENT_METHODS_FAILURE:
      return { ...newState, error: action.error.message };
    default:
      return state || newState;
  }
};

export default {
  checkoutShipment,
  checkoutShipmentMethodsResult
};
