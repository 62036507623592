/**
* Generated automatically at built-time (2025-01-17T10:14:46.863Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "skeldervik",templateKey: "sites/101-eb325a0b-d799-4416-bd01-29d60045df12"};