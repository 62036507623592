import { connectHOCs } from "@components-utils";
import ProductPriceProps from "@prop-types/ProductPriceProps";
import TitleTextProps from "@prop-types/TitleTextProps";
import { CheckoutSubtotalBS } from "@style-variables";
import {
  formatCurrency,
  formatNumber,
  getComponentClassName
} from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";

const CheckoutSubtotal = ({
  isCartEmpty,
  className,
  amount,
  currency,
  i18n,
  vat,
  discount,
  shipment,
  subtotal
}) => {
  if (isCartEmpty) {
    return null;
  }

  const _i18n = subtotal.i18n;

  const _formatCurrency = value =>
    formatCurrency(
      formatNumber(value, currency.decimal, currency.thousand),
      currency.prefix,
      currency.suffix
    );

  return (
    <div className={getComponentClassName(CheckoutSubtotalBS, null, className)}>
      <div>
        <div className={getComponentClassName(CheckoutSubtotalBS, "gross")}>
          <span>{_i18n.cartValue}</span>
          <span> {_formatCurrency(amount)}</span>
        </div>
        <div className={getComponentClassName(CheckoutSubtotalBS, "shipping")}>
          <span>{_i18n.shippingValue}</span>
          <span>{_formatCurrency(shipment)}</span>
        </div>
        <div className={getComponentClassName(CheckoutSubtotalBS, "discount")}>
          <span>{_i18n.cartDiscount}</span>
          <span>{_formatCurrency(discount)}</span>
        </div>
        <div className={getComponentClassName(CheckoutSubtotalBS, "vat")}>
          <span>{_i18n.vatValue}</span>
          <span>{_formatCurrency(vat)}</span>
        </div>
        <div
          className={getComponentClassName(
            CheckoutSubtotalBS,
            "total-discounted"
          )}
        >
          <span>{_i18n.orderValue}</span>
          <span>{_formatCurrency(amount)}</span>
        </div>
      </div>
    </div>
  );
};

CheckoutSubtotal.propTypes = {
  ...TitleTextProps,
  ...ProductPriceProps,
  className: PropTypes.string
};

CheckoutSubtotal.mapStateToProps = (state, ownProps) => {
  const gross = state.calculatorResult.gross || {};
  const vat = state.calculatorResult.vat || {};

  return {
    isCartEmpty: !state.cart.items.length,
    amount: gross.orderValue || 0,
    vat: vat.orderValue || 0,
    discount: gross.couponsValue || 0,
    shipment: gross.shipmentValue || 0
  };
};

CheckoutSubtotal.mapDispatchToProps = null;

CheckoutSubtotal.mapValueToProps = value => ({
  currency: value.currency,
  subtotal: value.checkout.subtotal
});

export default connectHOCs(CheckoutSubtotal, {
  withSite: true,
  withConnect: true
});
